import React, { useRef } from 'react';
import Slider from "react-slick";
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from 'react-icons/hi2';
import Heading from '../components/shared/heading';
import { MdOutlineCelebration } from "react-icons/md";
import { FaBirthdayCake } from "react-icons/fa";
import { TbChristmasTree } from "react-icons/tb";
import { GiFireworkRocket } from "react-icons/gi";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { FaOm } from "react-icons/fa";
import diwaliImg1 from "../assets/images/events/diwali/diwali1.webp";
import diwaliImg2 from "../assets/images/events/diwali/diwali2.webp";
import diwaliImg3 from "../assets/images/events/diwali/diwali3.webp";
import diwaliImg4 from "../assets/images/events/diwali/diwali4.webp";
import diwaliImg5 from "../assets/images/events/diwali/diwali5.webp";
import diwaliImg6 from "../assets/images/events/diwali/diwali6.webp";
import diwaliImg7 from "../assets/images/events/diwali/diwali7.webp";
import diwaliImg8 from "../assets/images/events/diwali/diwali8.webp";
import birthdayImg1 from "../assets/images/events/birthday/birthday1.webp";
import birthdayImg2 from "../assets/images/events/birthday/birthday2.webp";
import birthdayImg3 from "../assets/images/events/birthday/birthday3.webp";
import birthdayImg4 from "../assets/images/events/birthday/birthday4.webp";
import birthdayImg5 from "../assets/images/events/birthday/birthday5.webp";
import birthdayImg6 from "../assets/images/events/birthday/birthday6.webp";
import birthdayImg7 from "../assets/images/events/birthday/birthday7.webp";
import navaratriImg1 from "../assets/images/events/navaratri/navaratri1.webp";
import navaratriImg2 from "../assets/images/events/navaratri/navaratri2.webp";
import navaratriImg3 from "../assets/images/events/navaratri/navaratri3.webp";
import navaratriImg4 from "../assets/images/events/navaratri/navaratri4.webp";
import navaratriImg5 from "../assets/images/events/navaratri/navaratri5.webp";
import navaratriImg6 from "../assets/images/events/navaratri/navaratri6.webp";
import navaratriImg7 from "../assets/images/events/navaratri/navaratri7.webp";
import navaratriImg8 from "../assets/images/events/navaratri/navaratri8.webp";
import christmasImg1 from "../assets/images/events/christmas/christmas1.webp";
import christmasImg2 from "../assets/images/events/christmas/christmas2.webp";
import christmasImg3 from "../assets/images/events/christmas/christmas3.webp";
import christmasImg4 from "../assets/images/events/christmas/christmas4.webp";
import christmasImg5 from "../assets/images/events/christmas/christmas5.webp";

const OurJourney = () => {
  const sliderRefs = useRef([]);
  let settings = {
    slidesToShow: 1,
    arrows: false,
    dots: false,
  };

  return (
    <Layout>
      <InnerPageBanner title="Events" innerPageBanner={ImageIcons.contactBg} />

      <section>
        <div className='container'>
          <img className='w-full rounded-[10px]' src={ImageIcons.teamImg} alt='' />

          <div className='relative bg-white lg:-mt-[50px] md:-mt-[30px] mt-[30px] md:w-[95%] w-full mx-auto md:p-[30px] p-0 md:shadow-[0px_5px_15px_0px_rgba(106,121,255,0.27)] rounded-[10px]'>
            <h2>Outing, Fun, Joys —- You Can Associate It All The VP Group</h2>
            <p className='mb-0'>The VP Group believe that fostering a strong team spirit and cultivating a vibrant work culture are vital components of success. Our corporate outings are meticulously designed to bring together colleagues, promote camaraderie, and create lasting memories. Through a diverse range of activities and immersive experiences, we ensure that every outing is a celebration of teamwork and shared achievements. Take a sneak peek into our gallery to witness the genuine excitement, genuine connections, and sheer joy that infuse our corporate outings. These moments captured reflect the laughter, collaborative efforts, and genuine enthusiasm that characterise our gatherings.</p>
          </div>
        </div>
      </section>

      <section className='relative pt-0'>
        <div className='container '>
          <div className='flex lg:flex-nowrap flex-wrap items-center md:gap-[50px] gap-[40px]'>
            <div className='lg:w-[50%] w-full relative' >
              <div className='relative z-10 lg:max-w-[700px] max-w-full'>
                <h2 className='mb-[15px]'>Our Office</h2>
                <p className='mb-0'>Welcome to VP Group, which offers users with a broad assortment of services like IPTV, Cloud storage & CCTV Surveillance, Colocation, Telecommunication, Broadcasting, Real Estate, Aviation, Entertainment et cetera. We are always committed towards innovation and sustainability and it encourages us to provide smooth, safe and advanced solutions for the daily varying needs of different customers. We ensure providing users with fast  and reliable internet connections via our brands such as myInternet, myWifi and VP Broadband. Along with this, our broadcasting solutions allow users to immerse themselves into the world of digital entertainment, as we offer a wide array of content and programmes to our users, ensuring that they always have something new to watch. Besides this, we are also committed towards promoting sustainability and supporting your IT infrastructure through our myEV point and The Abacus cloud solution ventures. We are not only limited to this but we also provide 3 BHK independent flats, security solutions and colocation services via our ventures named Angan homes, MyCloudCam and Velosting.</p>
              </div>

            </div>
            <div className='relative lg:w-[50%] w-full'>
              <img className='w-full rounded-[10px]' src={ImageIcons.officeImgs} alt='' />
            </div>
          </div>
        </div>
      </section>

      <section className='relative bg-[#f3f4ff] events'>
        <div className='container !max-w-[1450px]'>
          <Heading title="Our Events" />

          <div className='  '>
            {eventsData.map((item, index) => {
              // const sliderRef = useRef(null);
              return (
                <div key={index} className='grid group items-center grid-cols-1 md:grid-cols-2 mb-[40px] last:mb-0 bg-white'>
                  <div className='relative md:group-even:order-1'>
                    <Slider ref={el => sliderRefs.current[index] = el} {...settings}>
                      {item.slideImgs.map((img, i) => (
                        <div key={i}>
                          <img className='w-full' src={img} alt={`Anniversary ${i + 1}`} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className='flex flex-col justify-center xl:p-[40px] md:p-[30px] p-[25px] relative'>
                    <div className='absolute right-[20px] bottom-[20px] text-[100px] opacity-50 text-[#f9daff]'>{item.icons}</div>
                    <h3 className='mb-[12px]'>{item.title}</h3>
                    <p>{item.description}</p>

                    <div className='flex gap-[5px]'>
                      <button
                        type="button"
                        className='xl:h-[45px] h-[40px] xl:w-[55px] w-[50px] bg-[#123a6d] duration-500 hover:bg-[#1036de] text-white flex justify-center items-center xl:text-[28px] text-[26px]'
                        onClick={() => sliderRefs.current[index].slickPrev()}
                      >
                        <HiOutlineArrowLongLeft />
                      </button>
                      <button
                        type="button"
                        className='xl:h-[45px] h-[40px] xl:w-[55px] w-[50px] bg-[#123a6d] duration-500 hover:bg-[#1036de] text-white flex justify-center items-center xl:text-[28px] text-[26px]'
                        onClick={() => sliderRefs.current[index].slickNext()}
                      >
                        <HiOutlineArrowLongRight />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className='relative'>
        <div className='container '>
          <div className='flex lg:flex-nowrap flex-wrap items-center md:gap-[50px] gap-[40px]'>
            <div className='relative lg:w-[50%] w-full'>
              <img className='w-full rounded-[10px]' src={ImageIcons.officeImgs} alt='' />
            </div>
            <div className='lg:w-[50%] w-full relative' >
              <div className='relative z-10 lg:max-w-[700px] max-w-full'>
                <h2 className='mb-[15px]'>Want to Join Us?</h2>
                <p>Welcome to VP Group, which offers users with a broad assortment of services like IPTV, Cloud storage & CCTV Surveillance, Colocation, Telecommunication, Broadcasting, Real Estate, Aviation, Entertainment et cetera. We are always committed towards innovation and sustainability and it encourages us to provide smooth, safe and advanced solutions for the daily varying needs of different customers. We ensure providing users with fast  and reliable internet connections via our brands such as myInternet, myWifi and VP Broadband.</p>

                <ul className='list !mb-0'>
                  <li>We help you upskill your talent pool.</li>
                  <li>We appreciate you for the good work.</li>
                  <li>Best experts to help you in your journey.</li>
                  <li>Nurture long term relationships with employees too.</li>
                  <li className='!mb-0'>We make a difference in the world.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )
}

const eventsData = [
  // {
  //   title: "VP Group Anniversary",
  //   description: "We always have a blast on our company’s anniversary and make it memorable for everyone. We not only celebrate the company’s success but also celebrate our employees. After all, they are the pillars of our unmatched success and growth.",
  //   icons: <LiaBirthdayCakeSolid />,
  //   slideImgs: [
  //     ImageIcons.anniversary_img1,
  //     ImageIcons.anniversary_img2,
  //     ImageIcons.anniversary_img3,
  //   ],
  // },
  // {
  //   title: "New Year Celebration",
  //   description: "The arrival of the New Year is a time for reflection, renewal, and celebration. Whether you’re hosting a lively party, embracing cultural traditions, or setting personal goals, there are countless ways to make the transition from the old year to the new one special. Here’s a comprehensive guide to help you plan a memorable New Year's celebration.",
  //   icons: <MdOutlineCelebration />,
  //   slideImgs: [
  //     ImageIcons.anniversary_img2,
  //     ImageIcons.anniversary_img2,
  //     ImageIcons.anniversary_img2,
  //   ],
  // },
  {
    title: "Diwali Celebration",
    description: "Diwali, also known as Deepavali, is one of the most vibrant and widely celebrated festivals in India and among the Indian diaspora. Often referred to as the Festival of Lights, Diwali symbolizes the victory of light over darkness, good over evil, and knowledge over ignorance. It’s a time of joy, reflection, and togetherness. Here’s a guide to help you celebrate Diwali with enthusiasm and reverence.",
    icons: <GiFireworkRocket />,
    slideImgs: [ diwaliImg1, diwaliImg2, diwaliImg3, diwaliImg4, diwaliImg5, diwaliImg6, diwaliImg7, diwaliImg8 ],
  },
  {
    title: "Christmas Celebration",
    description: "Christmas is a time of warmth, joy, and togetherness. Celebrated on December 25th, this holiday brings people together to honor the birth of Jesus Christ and to enjoy festive traditions with family and friends. Whether you're hosting a grand celebration or enjoying a quiet holiday at home, here's a comprehensive guide to making your Christmas special.",
    icons: <TbChristmasTree />,
    slideImgs: [ christmasImg1, christmasImg2, christmasImg3, christmasImg4, christmasImg5 ],
  },
  {
    title: "Birthday Celebration",
    description: "Nothing quite compares to the joy of seeing a big smile on a Birthday Boy’s or Girl’s face. Turning a birthday into a memorable experience requires a blend of thoughtful planning, vibrant decorations, engaging activities, and, of course, a delicious cake. Here’s how you can ensure that the birthday celebration becomes a highlight in the book of their life.",
    icons: <FaBirthdayCake />,
    slideImgs: [ birthdayImg1, birthdayImg2, birthdayImg3, birthdayImg4, birthdayImg5, birthdayImg6, birthdayImg7],
  },
  {
    title: "Navaratri Celebration",
    description: "Nothing quite compares to the joy of seeing a big smile on a Birthday Boy’s or Girl’s face. Turning a birthday into a memorable experience requires a blend of thoughtful planning, vibrant decorations, engaging activities, and, of course, a delicious cake. Here’s how you can ensure that the birthday celebration becomes a highlight in the book of their life.",
    icons: <FaOm />,
    slideImgs: [ navaratriImg1, navaratriImg2, navaratriImg3, navaratriImg4, navaratriImg5, navaratriImg6, navaratriImg7, navaratriImg8],
  },

];

export default OurJourney