import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { Link } from 'react-router-dom';
 
const Products = () => {
  return (
    <Layout>
      <InnerPageBanner title="Products" innerPageBanner={ImageIcons.contactBg} />
      <section>
        <div className='container'>
          {/* <h2 className='mb-[40px] text-center'>VP Group of Companies</h2> */}

          <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-[20px] text-center'>
            <div className='shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] hover:border-b-[#123a6d] border-b-[4px] border-white hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] duration-700 rounded-[10px] p-[30px]'>
              <div className='w-[100px] h-[80px] mb-[20px] mx-auto'>
                <img src={ImageIcons.abacus} className='h-full w-full object-contain' alt=''></img>
              </div>
              <h3>The Abacus</h3>
              <p className='mb-[10px]'>TheAbacus is a leading cloud storage solution provider in India and our services include Private Cloud Services, Public Cloud Services, Rackspace, Bare Metal Services, Interconnection, storage Solutions, Content Delivery, VPS hosting Solutions, and Managed Network Services. Join us today and empower your digital journey to take your business to new heights. For more information, visit our website <Link to='https://www.theabacus.in/'>https://www.theabacus.in/</Link>.</p>
            </div>

            <div className='shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] hover:border-b-[#123a6d] border-b-[4px] border-white hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] duration-700 rounded-[10px] p-[30px]'>
               <div className='w-[100px] h-[80px] mb-[20px] mx-auto'>
                <img src={ImageIcons.skypro} className='h-full w-full object-contain' alt=''></img>
              </div>
              <h3>Skypro IPTV</h3>
              <p className='mb-[10px]'>Skypro is a premier IPTV services provider company, where we offer users with a wide range of channels and content, catering to different needs and preferences of the people. We have a community of thousands of satisfied customers. Come join us today and immerse yourself into the realm of entertainment. For any information regarding our plans and packages, visit our website <Link to='https://www.skypro.co.in/'>https://www.skypro.co.in/</Link> or contact us at +919803596035.</p>
            </div>

            <div className='shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] hover:border-b-[#123a6d] border-b-[4px] border-white hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] duration-700 rounded-[10px] p-[30px]'>
               <div className='w-[100px] h-[80px] mb-[20px] mx-auto'>
                <img src={ImageIcons.myEv} className='h-full w-full object-contain' alt=''></img>
              </div>
              <h3>MyEVpoint</h3>
              <p className='mb-[10px]'>As we are committed towards promoting a cleaner and greener tomorrow, we have come forward to promote electric vehicles with our initiative of EV charging stations in the name of myEVpoint. Our charging stations located at multiple popular spots ensure easy access to EV drivers along with encouraging others to adopt electric vehicles and abandon traditional one’s. For our services and purchasing our franchisee contact us today at info@vpventuresindia.com or visit our website <Link to='https://www.myevpoint.in/'>https://www.myevpoint.in/</Link>.</p>
            </div>

            <div className='shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] hover:border-b-[#123a6d] border-b-[4px] border-white hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] duration-700 rounded-[10px] p-[30px]'>
               <div className='w-[100px] h-[80px] mb-[20px] mx-auto'>
                <img src={ImageIcons.aangan} className='h-full w-full object-contain' alt=''></img>
              </div>
              <h3>Angan Homes</h3>
              <p className='mb-[10px]'>Angan homes is our real estate company based in Zirakpur. We are committed to offer you a unique experience of buying and selling properties in the beautiful and growing region of Zirakpur. We understand your needs and help you make a better decision regarding your investment. Not only this, we also help you in getting the best price for your property. If you are looking for investment or selling any of the properties, contact us at <Link to='https://anganhomes.com/'>https://anganhomes.com/</Link>.</p>
            </div>

            <div className='shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] hover:border-b-[#123a6d] border-b-[4px] border-white hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] duration-700 rounded-[10px] p-[30px]'>
               <div className='w-[100px] h-[80px] mb-[20px] mx-auto'>
                <img src={ImageIcons.myCloudCam} className='h-full w-full object-contain' alt=''></img>
              </div>
              <h3>MyCloudCam</h3>
              <p className='mb-[10px]'>myCloudCam is India’s first cloud based CCTV surveillance solutions offering company, where we allow the customer to have an access to the live camera view and recordings while sitting in any corner of the world at any time. The recordings are stored safely on the cloud, so that these can be accessed by you effortlessly. For our services, contact us at <Link to='https://www.mycloudcam.in/'>https://www.mycloudcam.in/</Link>.</p>
            </div>
          </div>
        </div>
      </section>
 
    </Layout>
  )
}


export default Products