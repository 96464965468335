import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from '../components/shared/layout';
import { GetRequest } from '../components/utils/request';
import { windowScroll } from '../components/utils/windowScroll';

const SiteMap = () => {

  const [blogs, setBlogs] = useState([])

  useEffect(() => {
    GetRequest(`${process.env.REACT_APP_API_URL}/users/getBlogs`)
      .then((response) => {
        console.log(response, "response")
        const activeBlogs = response.data.blogs.filter(blog => blog.isActive);
        setBlogs(activeBlogs);
      }).catch(err => {
        console.log(err);
      });
  }, [])

  return (
    <>

      <Layout>
        <InnerPageBanner title='Sitemap' innerPageBanner={ImageIcons.contactBg} />
        <section>
          <div className="container">
            <ul className="text-center">
              <li>
                <label className='bg-[#123A6D] w-full rounded-tl rounded-br rounded-[20px] relative p-[12px] mb-[20px] inline-block after:absolute after:contant-[""] after:w-[1px] after:h-[20px] after:bg-[#071e43] after:top-full after:left-[50%] after:-translate-x-2/4'><Link className='text-[22px] text-white hover:text-[#fff]' to="/">Home</Link></label>
                <ul className="flex justify-center max-md:flex-wrap max-md:border max-md:border-[#071e43] max-md:pb-[20px]">
                  {navigationData.map((item, index) => (
                    <li key={index} className='relative px-[10px] max-md:w-[90%] max-md:p-0 first:pl-0 last:pr-0 first:after:w-[50%] last:after:w-[50%] after:absolute after:contant-[""] after:w-full after:border-t after:border-[#071e43] after:top-0 after:right-0 last:after:left-0 before:absolute before:contant-[""] before:w-[1px] before:h-[20px] before:bg-[#071e43] before:top-0 before:left-[50%] before:-translate-x-2/4 max-md:after:hidden max-md:before:hidden'>
                      {item.title === "Blogs" ? (
                        <>
                          <Link to={item.type} onClick={() => { window.location.pathname = item.type }} className='text-white rounded-t-[8px] text-[18px] w-full text-center min-w-[100px] bg-[#123A6D] py-[12px] px-[20px] inline-block mt-[20px] underline hover:no-underline'>{item.title}</Link>
                          <ul className='max-md:border max-md:border-[#141416] max-md:pb-[20px] max-md:mt-[19px]'>
                            {blogs?.map((item, i) => (
                              <li key={i} className='relative max-md:w-[90%] max-md:mx-auto after:absolute after:hidden first:after:block contant-[""] after:w-[1px] after:h-[20px] after:bg-[#141416] after:top-0 max-md:after:top-[-20px] after:left-[50%] after:-translate-x-2/4 first:pt-[20px] pt-[10px]'>
                                <Link onClick={windowScroll} className='text-[#123A6D] hover:bg-[#1036de] border w-full text-center min-w-[100px] hover:text-[#fff] border-[#123A6D] py-[12px] px-[20px] inline-block rounded-[8px] ' to={`/blogs/${item?.handle}`}>{item?.title}</Link>
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <label className='text-white rounded-t-[8px] text-[18px] w-full text-center min-w-[100px] hover:text-[#fff] bg-[#123A6D] py-[12px] px-[20px] inline-block mt-[20px]'>{item.title}</label>
                      )}
                      {item.subMenu && (
                        <ul className="max-md:border max-md:border-[#141416] max-md:pb-[20px] max-md:mt-[19px]">
                          {item.subMenu.map((subItem, subIndex) => (
                            <li className='relative max-md:w-[90%] max-md:mx-auto after:absolute after:hidden first:after:block contant-[""] after:w-[1px] after:h-[20px] after:bg-[#141416] after:top-0 max-md:after:top-[-20px] after:left-[50%] after:-translate-x-2/4 first:pt-[20px] pt-[10px]' key={subIndex}>
                              <Link className='text-[#123A6D] hover:bg-[#1036de] border w-full text-center min-w-[100px] hover:text-[#fff] border-[#123A6D] hover:border-[#1036de] py-[12px] px-[20px] inline-block rounded-[8px] ' to={subItem.handle}
                              >{subItem.title}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </section>
      </Layout>
    </>
  )
}

const navigationData = [
  {
    title: "Resources",
    subMenu: [
      {
        title: "Events",
        handle: "/our-journey"
      },
      {
        title: "Testimonials",
        handle: "#"
      },
      {
        title: "Accreditations",
        handle: "/accreditations"
      }
    ]
  },
  {
    title: "Blogs",
    type: "/blogs",
    // subMenu: [
    //   {
    //     title: "Empowering a Connected World: The Future of Digital Experiences",
    //     handle: "#"
    //   },
    //   {
    //     title: "Seamless Connectivity: Pioneering the Next Wave of Digital Solutions",
    //     handle: "#"
    //   },
    //   {
    //     title: "The Future is Now: Innovations in OTT, Cloud Surveillance, and Data Centers",
    //     handle: "#"
    //   },

    // ]
  },
  {
    title: "Other Pages",
    subMenu: [
      {
        title: "About Us",
        handle: "/about-us"
      },
      {
        title: "Contact Us",
        handle: "/contact-us"
      },
      {
        title: "Our Products",
        handle: "/products"
      },
      // {
      //   title: "Career",
      //   handle: "/career"
      // },
      {
        title: "Privacy Policy",
        handle: "/privacy-policy"
      },
      {
        title: "Terms & Conditions",
        handle: "/terms-and-conditions"
      },
      {
        title: "Refund Policy",
        handle: "/cancellations-and-refunds"
      }
    ]
  },

];

export default SiteMap;