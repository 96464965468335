import React, {useState, useEffect} from 'react'
// import ImageIcons from '../imageComponent/ImageIcons';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { RiArrowRightLine } from 'react-icons/ri';
import { FaUser } from "react-icons/fa";
import { GetRequest } from '../utils/request';
import { windowScroll } from '../utils/windowScroll';
import Heading from '../shared/heading';

const Blogs = () => {

  const [blogs, setBlogs] = useState([])

  useEffect(() => {
    GetRequest(`${process.env.REACT_APP_API_URL}/users/getBlogs`)
      .then((response) => {
        console.log(response,"response") 
        const activeBlogs = response.data.blogs.filter(blog => blog.isActive);
        setBlogs(activeBlogs);
      }).catch(err => {
        console.log(err);
      });
  }, [])

  return (
    <section>
      <div className='container'>
        <Heading title="Latest Blog Posts" />
        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[20px] xl:gap-[30px]">
          {blogs.map((item, i) => (
            <Link key={i} to={`/blogs/${item?.handle}`} onClick={windowScroll} className='flex flex-col overflow-hidden bg-[#fff] group rounded-[10px] duration-500 shadow-[0px_0px_10px_rgba(0,0,0,0.09)] justify-start'>
              <div className='relative h-[280px] w-full overflow-hidden'>
                <img
                  src={item?.image}
                  alt={item?.alt}
                  className='w-full h-full object-center object-cover '
                />
              </div>
              <div className='md:p-[25px] p-[20px]'>
                <ul className='mb-[20px] flex items-center'>
                  <li className='flex items-center text-[15px] mr-[15px]'><FaUser className='inline-block text-[#123a6d] mr-[8px]' /> {item?.author}</li>
                  <li className='flex items-center text-[15px]'><FaRegCalendarAlt className='inline-block text-[#123a6d] mr-[8px]' /> {item?.publishedAt}</li>
                </ul>
                <div>
                  <h4 className='text-[20px] ine-clamp-2 mb-[15px] group-hover:text-[#6A79FF] duration-500 font-semibold leading-[28px]'>{item?.title}</h4>
                  <p className="ql-editor" dangerouslySetInnerHTML={{ __html: item?.description }} />
                  <span className='text-[#000] group-hover:text-[#D400FE] duration-500'>Read More <RiArrowRightLine className='inline-block' /></span>
                </div>

              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

// const blogsData = [
//   {
//     title: "Empowering a Connected World: The Future of Digital Experiences",
//     handle: "/blog-details",
//     blogImage: ImageIcons.blog1,
//     altText: "Blog image",
//     author: "Ram",
//     publishedAt: "23 February, 2024",
//     description: "Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.",
//   },
  
//   {
//     title: "Seamless Connectivity: Pioneering the Next Wave of Digital Solutions",
//     handle: "/blog-details",
//     blogImage: ImageIcons.blog1,
//     altText: "Blog image",
//     author: "Mohan",
//     publishedAt: "23 February, 2024",
//     description: "Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.",
//   },
//   {
//     title: "The Future is Now: Innovations in OTT, Cloud Surveillance, and Data Centers",
//     handle: "/blog-details",
//     blogImage: ImageIcons.blog1,
//     altText: "Blog image",
//     author: "Rahul",
//     publishedAt: "23 February, 2024",
//     description: "Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.",
//   }
// ]

export default Blogs